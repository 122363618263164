export const cityLogs = [
    {
        'city': '新北市',
        'log': [
            {
                "title": '第1天',
                "message": '從我家鄉出發， 三重 - 桃園中壢',
                "date": '2020-11-01'
            },
            {
                "title": '第53天',
                "message": '宜蘭大里->新北貢寮',
                "date": '2021-01-24'
            },
            {
                "title": '第54天',
                "message": '貢寮->九份',
                "date": '2021-01-25'
            },
            {
                "title": '第55天',
                "message": '九份->基隆',
                "date": '2021-01-26'
            },
            {
                "title": '第56天',
                "message": '基隆->金山',
                "date": '2021-01-27'
            },
            {
                "title": '第57天',
                "message": '金山->白沙灣',
                "date": '2021-01-28'
            },
            {
                "title": '第58天',
                "message": '白沙灣->三重（平安抵達家裡）',
                "date": '2021-01-29'
            },
        ]
    },
    {
        'city': '臺北市',
        'log': [
            {
                "title": '',
                "message": '我沒有經過這邊，都靠著邊邊走！',
                "date": ''
            },
        ]

    },
    {
        'city': '基隆市',
        'log': [
            {
                "title": '第55天',
                "message": '九份->基隆',
                "date": '2021-01-26'
            },
            {
                "title": '第56天',
                "message": '基隆->金山',
                "date": '2021-01-27'
            },
        ]
    },
    {
        'city': '桃園市',
        'log': [
            {
                "title": '第2天',
                "message": '桃園中壢->新竹竹北',
                "date": '2020-11-02',
                "remark" : "今天有一個也想要徒步環島的網友一起陪我走。"
            },
        ]
    },
    {
        'city': '新竹縣',
        'log': [
            {
                "title": '第2天',
                "message": '桃園中壢->新竹竹北',
                "date": '2020-11-02',
                "remark" : "今天有一個也想要徒步環島的網友一起陪我走。"
            },
            {
                "title": '第3天',
                "message": '新竹竹北->新竹香山',
                "date": '2020-11-03',
                "remark" : ""
            },
            {
                "title": '第4天',
                "message": '新竹香山->苗栗造橋',
                "date": '2020-11-04',
                "remark" : ""
            },
        ]
    },
    {
        'city': '新竹市',
        'log': [
            {
                "title": '第3天',
                "message": '新竹竹北->新竹香山',
                "date": '2020-11-03',
                "remark" : ""
            },
            {
                "title": '第4天',
                "message": '新竹香山->苗栗造橋',
                "date": '2020-11-04',
                "remark" : ""
            },
        ]
    },
    {
        'city': '苗栗縣',
        'log': [
            {
                "title": '第4天',
                "message": '新竹香山->苗栗造橋',
                "date": '2020-11-04',
                "remark" : ""
            },
            {
                "title": '第5天',
                "message": '苗栗造橋->苗栗三義',
                "date": '2020-11-05',
                "remark" : ""
            },
            {
                "title": '第6天',
                "message": '苗栗三義->台中沙鹿',
                "date": '2020-11-06',
                "remark" : ""
            },
        ]
    },
    {
        'city': '臺中市',
        'log': [
            {
                "title": '第6天',
                "message": '苗栗三義->台中沙鹿',
                "date": '2020-11-06',
                "remark" : ""
            },
            {
                "title": '第7天',
                "message": '台中沙鹿->台中烏日',
                "date": '2020-11-07',
                "remark" : ""
            },
            {
                "title": '第8天',
                "message": '「休息日」回朝陽逛一逛',
                "date": '2020-11-08',
                "remark" : ""
            },
            {
                "title": '第9天',
                "message": '台中朝陽->南投縣南投市',
                "date": '2020-11-09',
                "remark" : ""
            },
        ]
    },
    {
        'city': '南投縣',
        'log': [
            {
                "title": '第9天',
                "message": '台中朝陽->南投縣南投市',
                "date": '2020-11-09',
                "remark" : ""
            },
            {
                "title": '第10天',
                "message": '南投縣南投市->雲林斗六',
                "date": '2020-11-10',
                "remark" : "我只有削邊邊（二水），很快地就離開彰化了～下次再去玩～"
            },
        ]
    },
    {
        'city': '彰化縣',
        'log': [
            {
                "title": '第9天',
                "message": '台中朝陽->南投縣南投市',
                "date": '2020-11-09',
                "remark" : ""
            },
            {
                "title": '第10天',
                "message": '南投縣南投市->雲林斗六',
                "date": '2020-11-10',
                "remark" : "我只有削邊邊（二水），很快地就離開彰化了～下次再去玩～"
            },
        ]
    },
    {
        'city': '雲林縣',
        'log': [
            {
                "title": '第10天',
                "message": '南投縣南投市->雲林斗六',
                "date": '2020-11-10',
                "remark" : "我只有削邊邊（二水），很快地就離開彰化了～下次再去玩～"
            },
            {
                "title": '第11天',
                "message": '雲林斗六->嘉義',
                "date": '2020-11-11',
                "remark" : ""
            },
        ]
    },
    {
        'city': '嘉義縣',
        'log': [
            {
                "title": '第11天',
                "message": '雲林斗六->嘉義',
                "date": '2020-11-11',
                "remark" : ""
            },
            {
                "title": '第12天',
                "message": '嘉義->臺南柳營',
                "date": '2020-11-12',
                "remark" : ""
            },
        ]
    },
    {
        'city': '嘉義市',
        'log': [
            {
                "title": '第11天',
                "message": '雲林斗六->嘉義',
                "date": '2020-11-11',
                "remark" : ""
            },
            {
                "title": '第12天',
                "message": '嘉義->臺南柳營',
                "date": '2020-11-12',
                "remark" : ""
            },
        ]
    },
    {
        'city': '臺南市',
        'log': [
            {
                "title": '第12天',
                "message": '嘉義->臺南柳營',
                "date": '2020-11-12',
                "remark" : ""
            },
            {
                "title": '第13天',
                "message": '臺南柳營->拔林',
                "date": '2020-11-13',
                "remark" : ""
            },
            {
                "title": '第14天',
                "message": '南拔林->南市市區',
                "date": '2020-11-14',
                "remark" : ""
            },
            {
                "title": '第15天',
                "message": '南市市區->高雄岡山',
                "date": '2020-11-15',
                "remark" : ""
            },
        ]
    },
    {
        'city': '高雄市',
        'log': [
            {
                "title": '第15天',
                "message": '南市市區->高雄岡山',
                "date": '2020-11-15',
                "remark" : ""
            },
            {
                "title": '第16天',
                "message": '高雄岡山->高雄市區',
                "date": '2020-11-16',
                "remark" : ""
            },
            {
                "title": '第17天',
                "message": '「休息日」高雄市區',
                "date": '2020-11-17',
                "remark" : ""
            },
            {
                "title": '第18天',
                "message": '高雄市區->高雄林園',
                "date": '2020-11-18',
                "remark" : ""
            },
            {
                "title": '第19天',
                "message": '高雄林園->屏東東港',
                "date": '2020-11-19',
                "remark" : ""
            },
        ]
    },
    {
        'city': '屏東縣',
        'log': [
            {
                "title": '第19天',
                "message": '高雄林園->屏東東港',
                "date": '2020-11-19',
                "remark" : ""
            },
            {
                "title": '第20天',
                "message": '「休息日」屏東東港',
                "date": '2020-11-20',
                "remark" : "坐船去小琉球玩一下～第一次看到海龜～超開心！"
            },
            {
                "title": '第21天',
                "message": '東港->枋山',
                "date": '2020-11-21',
                "remark" : ""
            },
            {
                "title": '第22天',
                "message": '枋山->楓港',
                "date": '2020-11-22',
                "remark" : "今天的我猶豫了一下～要往南部繼續走大圈的環島還是直接右轉"
            },
            {
                "title": '第23天',
                "message": '楓港->恆春',
                "date": '2020-11-23',
                "remark" : "睡飽以後決定繼續往南部走"
            },
            {
                "title": '第24天',
                "message": '恆春->墾丁',
                "date": '2020-11-24',
                "remark" : ""
            },
            {
                "title": '第25天',
                "message": '墾丁->港口',
                "date": '2020-11-25',
                "remark" : "終於到最南端，開始北上囉～～～"
            },
            {
                "title": '第26天',
                "message": '港口->滿州',
                "date": '2020-11-26',
                "remark" : ""
            },
            {
                "title": '第27天',
                "message": '滿州->旭海',
                "date": '2020-11-27',
                "remark" : ""
            },
            {
                "title": '第28天',
                "message": '旭海->台東達仁',
                "date": '2020-11-28',
                "remark" : ""
            },
        ]
    },
    {
        'city': '臺東縣',
        'log': [
            {
                "title": '第28天',
                "message": '旭海->台東達仁',
                "date": '2020-11-28',
                "remark" : ""
            },
            {
                "title": '第29天',
                "message": '達仁->尚武',
                "date": '2020-11-29',
                "remark" : ""
            },
            {
                "title": '第30天',
                "message": '尚武->太麻里鄉金崙村',
                "date": '2020-11-30',
                "remark" : ""
            },
            {
                "title": '第31天',
                "message": '「休息日」太麻里鄉金崙村',
                "date": '2020-11-31',
                "remark" : ""
            },
            {
                "title": '',
                "message": '暫停',
                "date": '',
                "remark" : "因為要回去吃喜酒，所以暫停我的徒步環島旅程～"
            },
            {
                "title": '第32天',
                "message": '太麻里鄉金崙村->太麻里鄉',
                "date": '2021-01-03',
                "remark" : ""
            },
            {
                "title": '第33天',
                "message": '太麻里鄉->知本',
                "date": '2021-01-04',
                "remark" : ""
            },
            {
                "title": '第34天',
                "message": '知本->台東市',
                "date": '2021-01-05',
                "remark" : ""
            },
            {
                "title": '第35天',
                "message": '台東市->鹿野',
                "date": '2021-01-06',
                "remark" : ""
            },
            {
                "title": '第36天',
                "message": '鹿野->關山',
                "date": '2021-01-07',
                "remark" : ""
            },
            {
                "title": '第37天',
                "message": '關山->池上',
                "date": '2021-01-08',
                "remark" : ""
            },
            {
                "title": '第38天',
                "message": '池上->東里',
                "date": '2021-01-09',
                "remark" : ""
            },
        ]
    },
    {
        'city': '花蓮縣',
        'log': [
            {
                "title": '第38天',
                "message": '池上->東里',
                "date": '2021-01-09',
                "remark" : ""
            },
            {
                "title": '第39天',
                "message": '玉里->東里',
                "date": '2021-01-10',
                "remark" : "今天南下倒著走，沒想到火車班次太少，所以要等一下，決定走回昨天的火車站"
            },
            {
                "title": '第40天',
                "message": '玉里->瑞穗',
                "date": '2021-01-11',
                "remark" : ""
            },
            {
                "title": '第41天',
                "message": '瑞穗->光復',
                "date": '2021-01-12',
                "remark" : ""
            },
            {
                "title": '第42天',
                "message": '光復->豐田火車站',
                "date": '2021-01-13',
                "remark" : ""
            },
            {
                "title": '第43天',
                "message": '豐田車站->花蓮市區',
                "date": '2021-01-14',
                "remark" : ""
            },
            {
                "title": '第44天',
                "message": '「休息日」花蓮市區',
                "date": '2021-01-15',
                "remark" : "坐徒友推薦的公車，去太魯閣國家公園玩，今天走了很多個步道，還認識一對很聊得來夫妻"
            },
            {
                "title": '第45天',
                "message": '「休息日」花蓮市區',
                "date": '2021-01-16',
                "remark" : "繼續休息，因為昨天太累了！"
            },
            {
                "title": '第46天',
                "message": '花蓮市區->新城',
                "date": '2021-01-17',
                "remark" : ""
            },
            {
                "title": '第47天',
                "message": '新城->和平',
                "date": '2021-01-18',
                "remark" : "開始走蘇花公路了！預計三天"
            },
            {
                "title": '第48天',
                "message": '和平->南澳',
                "date": '2021-01-19',
                "remark" : ""
            },
        ]
    },
    {
        'city': '宜蘭縣',
        'log': [
            {
                "title": '第48天',
                "message": '和平->南澳',
                "date": '2021-01-19',
                "remark" : ""
            },
            {
                "title": '第49天',
                "message": '南澳->蘇澳',
                "date": '2021-01-20',
                "remark" : ""
            },
            {
                "title": '第50天',
                "message": '蘇澳->羅東',
                "date": '2021-01-21',
                "remark" : ""
            },
            {
                "title": '第51天',
                "message": '羅東->礁溪',
                "date": '2021-01-22',
                "remark" : ""
            },
            {
                "title": '第52天',
                "message": '礁溪->大里',
                "date": '2021-01-23',
                "remark" : ""
            },
            {
                "title": '第53天',
                "message": '宜蘭大里->新北貢寮',
                "date": '2021-01-24',
                "remark" : ""
            },
        ]
    },
];
