import Vue from 'vue';

import MapComponent from '../vue-component/map'
new Vue({
    el: '#app',
    components:{
        MapComponent
    },
    data() {
        return {
        }
    },
    mounted() {
    }
});

