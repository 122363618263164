import Chart from 'chart.js/auto';

const resData = [
    ['2020/11/01', '1', '54,802', '37.69'],
    ['2020/11/02', '2', '51,510', '35.3'],
    ['2020/11/03', '3', '27,908', '15.08'],
    ['2020/11/04', '4', '36,514', '19.17'],
    ['2020/11/05', '5', '42,643', '23.56'],
    ['2020/11/06', '6', '48,868', '29.93'],
    ['2020/11/07', '7', '29,954', '23.01'],
    ['2020/11/08', '8', '0', '0'],
    ['2020/11/09', '9', '37,211', '27.2'],
    ['2020/11/10', '10', '39,450', '20.81'],
    ['2020/11/11', '11', '50,700', '22.98'],
    ['2020/11/12', '12', '32,539', '19.28'],
    ['2020/11/13', '13', '37,244', '18.22'],
    ['2020/11/14', '14', '36,858', '20.27'],
    ['2020/11/15', '15', '43,908', '20.18'],
    ['2020/11/16', '16', '39,195', '16.41'],
    ['2020/11/17', '17', '0', '0'],
    ['2020/11/18', '18', '36,724', '19.32'],
    ['2020/11/19', '19', '28,687', '11.27'],
    ['2020/11/20', '20', '0', '0'],
    ['2020/11/21', '21', '40,238', '28.11'],
    ['2020/11/22', '22', '26,218', '15.37'],
    ['2020/11/23', '23', '38,298', '25.31'],
    ['2020/11/24', '24', '23,324', '14.47'],
    ['2020/11/25', '25', '35,812', '21.54'],
    ['2020/11/26', '26', '20,819', '7.42'],
    ['2020/11/27', '27', '50,553', '32.78'],
    ['2020/11/28', '28', '48,814', '30.23'],
    ['2020/11/29', '29', '13,125', '6.16'],
    ['2020/11/30', '30', '45,497', '26.74'],
    ['2020/12/01', '31', '0', '0'],
    ['2021-01-03', '32', '20,463', '7.69'],
    ['2021-01-04', '33', '31,559', '19.63'],
    ['2021-01-05', '34', '25,130', '13.74'],
    ['2021-01-06', '35', '34,299', '21.88'],
    ['2021-01-07', '36', '32,101', '19.46'],
    ['2021-01-08', '37', '21,745', '14.02'],
    ['2021-01-09', '38', '39,609', '22.33'],
    ['2021-01-10', '39', '24,329', '11.42'],
    ['2021-01-11', '40', '36,387', '24.77'],
    ['2021-01-12', '41', '36,403', '21.82'],
    ['2021-01-13', '42', '43,993', '25.26'],
    ['2021-01-14', '43', '33,173', '22.48'],
    ['2021-01-15', '44', '0', '0'],
    ['2021-01-16', '45', '0', '0'],
    ['2021-01-17', '46', '34,408', '23.46'],
    ['2021-01-18', '47', '43,138', '28.73'],
    ['2021-01-19', '48', '45,897', '28.72'],
    ['2021-01-20', '49', '42,778', '29.35'],
    ['2021-01-21', '50', '25,704', '15.17'],
    ['2021-01-22', '51', '28,763', '18.93'],
    ['2021-01-23', '52', '37,918', '26.24'],
    ['2021-01-24', '53', '27,186', '14.9'],
    ['2021-01-25', '54', '36,568', '24.88'],
    ['2021-01-26', '55', '25,292', '16.26'],
    ['2021-01-27', '56', '33,187', '23.19'],
    ['2021-01-28', '57', '28,586', '19.15'],
    ['2021-01-29', '58', '51,570', '36.48']
];

const labels = [];

resData.forEach(item => {
    labels.push('第' + item[1] + '天');
})

let chartData = []

resData.forEach(item => {
    chartData.push(parseFloat(item[2].replace(',', '')));
})

let total = 0;
let totalArray = [];

resData.forEach(item => {
    total = total + parseFloat(item[2].replace(',', ''));
    totalArray.push(total);
})

const data = {
    labels: labels,
    datasets: [
        {
            label: '環島步數',
            data: chartData,
            backgroundColor: 'rgba(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 1,
            stack: 'combined',
            type: 'bar'
        }
    ]
};
let delayed;
const config = {
    type: 'line',
    data: data,
    options: {
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 30 + context.datasetIndex * 20;
                }
                return delay;
            },
        },
        scales: {

            x: {
                grid: {
                    display: false
                },
                ticks: {}
            },
            y: {
                ticks: {
                    lineWidth: 0,
                    // Include a dollar sign in the ticks
                    callback: function (value) {
                        return value + '步';
                    }
                }
            }
        }
    },
}

window.addEventListener("load", function () {
    new Chart(
        document.getElementById('myChart'),
        config
    );
});
