<template>
  <div class="col-12 page">
    <div class="row">
      <div class="col-md-6">
        <div id="taiwanMap" class="taiwan-map" ref="map">
          <div id="map">
            <svg id="svg" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"></svg>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h1>{{ hTitle }}</h1>
        <ul class="list-group  mb-2" v-for="log in cityLog">
          <li class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ log.title }}</h5>
              <small>{{ log.date }}</small>
            </div>
            <p class="mb-1">{{ log.message }}</p>
            <small>{{ log.remark }}</small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import taiwanJson from "../data/taiwan2.json";
import {cityLogs} from "../js/cityLogs";

export default {
  name: "map-component",
  data() {
    return {
      hTitle: '點選縣市',
      cityLog: [{
        "title": '',
        "message": '我會告訴你我待了幾天',
        "date": '2020-11-01 - 2021-01-29',
        "remark": ""
      }],
    }
  },
  mounted() {
    this.getTaiwanMap().then(() => console.log('ok'));
    // this.getArticle().then(() => console.log('ok'));
  },
  methods: {
    async getTaiwanMap() {
      let ele = document.getElementById('taiwanMap');
      const width = ele.offsetWidth.toFixed(),
          height = ele.offsetHeight.toFixed();
      // 判斷螢幕寬度，給不同放大值
      let mercatorScale, w = window.screen.width;
      if (w > 1366) {
        mercatorScale = 7000;
      } else if (w <= 1366 && w > 480) {
        mercatorScale = 7000;
      } else {
        mercatorScale = 6000;
      }

      // d3：svg path 產生器
      let path = await d3.geo.path().projection(
          // !important 座標變換函式
          d3.geo
              .mercator()
              .center([121, 24])
              .scale(mercatorScale)
              .translate([width / 2, height / 2.5])
      );

      // 讓d3抓svg，並寫入寬高
      let svg = await d3.select('#svg')
          .attr('width', width)
          .attr('height', height)
          .attr('viewBox', `0 0 ${width} ${height}`);

      // 讓d3抓GeoJSON檔，並寫入path的路徑
      const url = taiwanJson;

      // 讓d3抓svg，並寫入寬高
      d3.json(url, (error, geometry) => {
        if (error) throw error;

        svg
            .selectAll('path')
            .data(geometry.features)
            .enter().append('path')
            .attr('d', path)
            .attr({
              // 設定id，為了click時加class用
              id: (d) => 'city、' + d.properties.COUNTYCODE
            })
            .on('click', d => {
              this.hTitle = d.properties.COUNTYNAME; // 換中文名
              this.cityLog = this.showInfo(d.properties.COUNTYNAME)
              this.h2 = d.properties.COUNTYENG; // 換英文名
              // 有 .active 存在，就移除 .active
              if (document.querySelector('.active')) {
                document.querySelector('.active').classList.remove('active');
              }
              // 被點擊的縣市加上 .active
              document.getElementById('city、' + d.properties.COUNTYCODE).classList.add('active');
            });
      });
      return svg;
    },
    showInfo(city) {
      let result = '';
      result = cityLogs.find(o => o.city === city);
      if (typeof result === 'object' && result !== null) {
        return result.log;
      }
      return result;
    },
    getArticle() {
      return fetch('https://www.vnewin.com/wp-json/wp/v2/posts?categories=165&per_page=60&_fields=title,link')
          .then(response => response.text())
          .then(text => {
            console.log(text);
          }).catch(err => {
            console.error('fetch failed', err);
          });

    }
  },
}
</script>

<style scoped>

</style>
