import "./js/CountUp";
import "./js/chart";
import './sass/index.scss'
import "./js/vue"
// function search(nameKey, myArray) {
//     for (var i = 0; i < myArray.length; i++) {
//         if (myArray[i].city === nameKey) {
//             return myArray[i];
//         }
//     }
// }
// const  gs = require('./js/gs.js');
//
//
// (async () => {
//     const resp = await gs.getData('13h4pPu7PNKfIZ9HXtjvzUWtpgxNG8wDVyzByQuFBATc', '0', './travelaroundtaiwan-317404-953f3865028d.json');
//     console.log(resp);
// })();


window.addEventListener("load", function () {
    let loaders = document.getElementsByClassName('loader');
    for (const loader of loaders) {
        loader.style.display = "none";
    }

    document.getElementById('myvideo').play();
});
