import { CountUp } from 'countup.js';

let countDayCountUp = new CountUp("countDay", 58);
countDayCountUp.start();

let peopleCountUp = new CountUp("people", 287);
peopleCountUp.start();

let totalwalk = 1117.77;

let nightMarket001CountUp = new CountUp("night_market001", totalwalk / (0.65 * 1));
nightMarket001CountUp.start();

let nightMarket002CountUp = new CountUp("night_market002", totalwalk / (10 * 1));
nightMarket002CountUp.start();

let nightMarket003CountUp = new CountUp("night_market003", totalwalk / (0.2 * 1));
nightMarket003CountUp.start();

const options = {
    decimalPlaces: 2,
};
let kmsCountUp = new CountUp("kms", 1117.77, options);
kmsCountUp.start();


if (!kmsCountUp.error) {
    kmsCountUp.start();
} else {
    console.error(kmsCountUp.error);
}
